import { useLocation, useParams as useMatchedParams } from "react-router-dom";

import camelCase from "lodash-es/camelCase";

export function splitCommaSeparatedIntegerString(
  s: string | number[]
): number[] {
  if (Array.isArray(s)) {
    // It's already an array; no transformation needed
    return s;
  }

  return s
    .replace(/%2C/g, ",")
    .split(",")
    .map((s) => parseInt(s, 10))
    .filter((i) => !isNaN(i));
}

export function getQueryParams(search: string): { [key: string]: any } {
  return search
    .substr(1, search.length)
    .split("&")
    .reduce((acc, cur) => {
      const item = cur.split("=");
      return {
        ...acc,
        [camelCase(item[0])]: item[1],
      };
    }, {});
}

export function useQueryParams(): { [key: string]: any } {
  const { search } = useLocation();
  return getQueryParams(search);
}

export function useParams(): { [key: string]: any } {
  const matchedParams = useMatchedParams();
  const queryParams = useQueryParams();

  return {
    ...matchedParams,
    ...queryParams,
  };
}
