import axios, { AxiosRequestConfig } from "axios";
import { AxiosResponse } from "axios";
import { get } from "lodash-es";

import useAxios from "@smartrent/use-axios";

import { VerificationApiType } from "@/types";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
});

interface AuthenticatedRequest extends AxiosRequestConfig {
  accessToken: null | string;
}

// We can't use `.get` / `.post` / etc syntax for this abstraction
// (you have to pass in an object with `method: "POST"`, etc)
// But this buys us the ability to refresh globally on 401,
// which will allow us catch that the token has expired and
// prompt them to re-validate their phone.
export const authenticatedRequest = (
  config: AuthenticatedRequest
): Promise<AxiosResponse<any, VerificationApiType>> => {
  // If we don't have an accessToken we could either reload the window and window up in a potential infinite loop
  // or we can throw an error and potentially risk not recovering from it
  // If we got this far with no accessToken, we're probably in trouble
  if (!config.accessToken) {
    console.error(
      "Cannot make authenticated request, please verify your phone number first"
    );
    window.location.reload();
  }

  return optionallyAuthenticatedRequest(config);
};

export const optionallyAuthenticatedRequest = (
  config: AuthenticatedRequest
): Promise<AxiosResponse<any, VerificationApiType>> => {
  return instance({
    ...config,
    headers: {
      authorization: `Bearer ${config.accessToken}`,
    },
  }).catch((error) => {
    if (get(error, "response.status") === 401) {
      // The access token is expired / missing, so reload the page,
      // which will prompt the user to re-verify their phone via two factor auth.
      // Note: We should not be checking on the frontend if the token is expired.
      // There's still a chance the server can send a 401 back due to race conditions
      // but in general we should expect these to occur less frequently now.
      localStorage.removeItem("accessTokens");
      window.location.reload();
    }

    // Allow the next `catch` to receive this error
    throw error;
  });
};

export const useApi = (params: any) => {
  const options = params.options || {};

  // To do: find a way to not duplicate this functionality of injecting the token
  if (params.accessToken) {
    options.headers = { authorization: "Bearer " + params.accessToken };
  }

  return useAxios({
    axios: instance,
    ...params,
    options: options,
  });
};
