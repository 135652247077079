export type TimeTuple = [number, number]; // [hour, minute]

export interface DaySchedule {
  dateString: string; // ISO8601 date string, e.g. "2020-10-03"
  date: Date;
  times: TimeSlot[];
}

export interface TimeSlot {
  available: boolean;
  time: string; // e.g. ISO8601 time string, e.g. "14:30:00"
  dateString: string; // ISO8601 date string, e.g. "2020-10-03"
  dateTimeString: string; // e.g. "2020-10-03T23:30:00Z"
  dateTimeObject: Date;
}

export interface ApiDaySchedule {
  date: string; // ISO8601 date string, e.g. "2020-10-03"
  times: Array<{
    available: boolean;
    time: string; // e.g. ISO8601 time string, e.g. "14:30:00"
    date_time: string; // e.g. "2020-10-03T23:30:00Z"
  }>;
}

export enum DayOfWeek {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}
