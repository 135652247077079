import React from "react";
import { ActivityIndicator, Text, View } from "react-native";

interface FullScreenLoaderProps {
  testID?: string;
}

export default function FullScreenLoader({ testID }: FullScreenLoaderProps) {
  return (
    <View
      testID={testID}
      style={{
        width: "100%",
        height: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator size="large" />
      <Text
        style={{
          margin: 0,
          padding: 0,
          width: "100%",
          textAlign: "center",
          color: "rgb(106, 110, 121)",
          fontFamily: "Open Sans",
          fontWeight: 600,
          fontSize: 24,
          marginTop: 15,
        }}
      >
        Loading
      </Text>
    </View>
  );
}
