import { get } from "lodash-es";
import platform from "platform";

export const getWebPlatform = () => {
  return get(platform, "os.family", "Android");
};

export const isIOS = () =>
  getWebPlatform() === "iOS" &&
  window.navigator.vendor === "Apple Computer, Inc.";

export const disableIosZoom = () => {
  if (isIOS()) {
    document.addEventListener(
      "touchmove",
      function (event: any) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
    // disable pinch zoom
    var lastTouchEnd = 0;
    document.addEventListener(
      "touchend",
      function (event: any) {
        var now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    // disable double tap to zoom
  }
};
