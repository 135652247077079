import { ApiConfigType, ConfigType } from "@/types";

export function normalizeTourConfig(config: ApiConfigType): ConfigType {
  return {
    blockInternationalPhoneNumbers: config.block_international_phone_numbers,
    enableClickToUnlock: config.enable_click_to_unlock,
    allowPassportVerification: config.allow_passport_verification,
    surveyEnabled: config.survey_enabled,
    customSurveyLink: config.custom_survey_link,
    applicationLinkUrl: config.application_link_url,
    blockedProspectMessage: config.blocked_prospect_message,
    uuid: config.uuid,
    groupId: config.group_id,
    propertyName: config.property_name,
    propertyImageUrl: config.property_image_url,
    propertyLogoUrl: config.property_logo_url,
    timezone: config.group.timezone,
    imageUrl: config.property_image_url,
    maxUnits: config.max_units,
    tourAllEnabled: config.tour_all_enabled,
    industry: config.industry,
    disableDoubleBookings: config.disable_double_bookings,
    color: config.primary_color,
    phoneNumber: config.phone_number,
    mondayStart: config.monday_start,
    mondayEnd: config.monday_end,
    tuesdayStart: config.tuesday_start,
    tuesdayEnd: config.tuesday_end,
    wednesdayStart: config.wednesday_start,
    wednesdayEnd: config.wednesday_end,
    thursdayStart: config.thursday_start,
    thursdayEnd: config.thursday_end,
    fridayStart: config.friday_start,
    fridayEnd: config.friday_end,
    saturdayStart: config.saturday_start,
    saturdayEnd: config.saturday_end,
    sundayStart: config.sunday_start,
    sundayEnd: config.sunday_end,
    indemnityAgreementUrl: config.indemnity_agreement_url,
    tourDuration: config.tour_duration,
    tourEndBuffer: config.tour_end_buffer,
    accessCodeEarlyAccess: config.access_code_early_access,
    welcomeMessage: config.welcome_message,
    welcomeVideoLink: config.welcome_video_link,
    alwaysShowWelcomeMessage: config.always_show_welcome_message,
    endTourMessage: config.end_tour_message,
    endOfTourVideoLink: config.end_of_tour_video_link,
    communityMap: config.community_map_image_url,
    engrainMap: config.engrain_map_url,
    engrainAssetId: config.engrain_asset_id,
    engrainPricingProcessId: config.engrain_pricing_process_id,
    engrainFloorData: config.engrain_floor_data,
    engrainUnitMapId: config.engrain_unit_map_id,
    engrainUnitMapsEnabled: config.engrain_unit_maps_enabled,
    showUnitAddress: config.show_unit_address,
    skipIdVerification: config.skip_id_verification,
    geofenceRadiusMiles: config.geofence_radius_miles,
    waitListEnabled: config.wait_list_enabled,
    prospectJoinWaitlistMessage: config.prospect_join_waitlist_message,
    showBleAccessOnCommunityAccessCard:
      config.show_ble_access_on_community_access_card,
    rentRollEnabled: config.rent_roll_enabled,
    scammerAlertMessagesEnabled: config.scammer_alert_messages_enabled,
    scammerAlertMessages: config.scammer_alert_messages,
    country: config.group.country,
    covidAgreementEnabled: config.covid_agreement_enabled,
    funnelSMSOptIn: config.funnel_sms_opt_in,
    tourScheduleAdvanceDays: config.tour_schedule_advance_days,
  };
}
