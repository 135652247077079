import React from "react";
import { Image, Text, View } from "react-native";

import notFoundImage from "@/assets/images/404.svg";

export default function NotFound({
  message = "Page Not Found",
}: {
  message?: string;
}) {
  const ratio = 0.997767857;

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        source={notFoundImage}
        style={{
          width: 300,
          height: 300 / ratio,
        }}
      />
      <Text
        style={{
          marginTop: 16,
          fontWeight: 600,
          fontSize: 20,
          lineHeight: 28,
          letterSpacing: 0,
          fontFamily: "Open Sans",
        }}
      >
        {message}
      </Text>
    </View>
  );
}
